<template>
  <div class="" >
    <el-dialog title="详情" :close-on-click-modal="false" :visible.sync="visible" width="60%" @closed="dialogClosed()">
      
      <div class="f_t" align="right">
        <el-button-group>
          <el-button type="warning" @click="exportData()">{{ $t('export') }}</el-button>
        </el-button-group>
      </div>
        <el-table :data="dataList" border v-loading="dataListLoading" height="280"
              style="width: 100%;">
            <el-table-column
                prop="pareaCode"
                header-align="center"
                align="center"
                label="上级公司">
                <template slot-scope="scope">
                <org :value="scope.row.pareaCode"></org>
                </template>
            </el-table-column>
            <el-table-column
            prop="areaCode"
            header-align="center"
            align="center"
            label="网格名称">
            <template slot-scope="scope">
                <org :value="scope.row.areaCode"></org>
                </template>
            </el-table-column>
            <el-table-column
            prop="expSum"
            header-align="center"
            align="center"
            label="异常数量">
            </el-table-column>
        </el-table>
        <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
            :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalCount"
            layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      
    </el-dialog>
  </div>
</template>
<script>
import {getInfoList } from "@/api/kanban/majorWarning";
export default {
    data () {
        return {
            visible: false,
            dataForm:{
                id:null,
            },
            dataList: [], // 可关联事项列表
            pageIndex: 1,
            pageSize: 10,
            totalCount: 0,
            dataListLoading: false
        }
    },
    // 注册组件
  components: {
    
  },
  computed: {

  },
  activated () {

},
methods: {
    init(id){
        this.visible = true;
        this.dataForm.id = id;
        this.getDataList();
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
      this.refresh()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
      this.refresh()
    },
   //关闭前回调到第一页
    dialogClosed(){
      this.pageIndex = 1
    },
     // 查询窗口未关联事项
     getDataList () {
      // if (this.itemDataForm.depId != '' && this.itemDataForm.depId != undefined) {
        this.dataListLoading = true
        // const params_ = {
        //     'id':this.dataForm.id,
        //   'page': this.pageIndex,
        //   'limit': this.pageSize
        // }
        getInfoList(
            {
                id:this.dataForm.id,
                page: this.pageIndex,
                limit: this.pageSize
            }
        ).then(({ data }) => {
          if (data && data.code === 0) {
            this.dataList = data.data.list
            this.totalCount = data.data.totalCount
          } else {
            this.dataList = []
            this.totalCount = 0
            this.$message.error(data.msg)
          }
        }).catch((err) => {
          console.error(err)
        }).finally(() => {
          this.dataListLoading = false
          this.tableKey = new Date().toString()
        })
      // }
    },
    exportData(){
      const token = this.$cookie.get('x-tr-mimosa-token') // this.$cookie.get('token')
      // 导出数据
      const time = new Date().getTime()
      const url = this.$http.adornUrl(`/dataflow/olfmajorwarining/export?` +
        'token=' + token +
        '&time=' + time +
        '&id='+ this.dataForm.id
        // +
        // '&page='+ this.pageIndex+
        // '&limit='+ this.pageSize
      )
      window.location.href = url
    },
}
}
</script>